import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { ReviewCarousel } from './ReviewCarousel';

export const ClientsReview = () => {
  return (
    <div className="relative md:bg-light bg-white">
      <Container className="md:pt-18-vw md:pb-12.5-vw py-10">
        <section className="md:bg-white justify-center md:pt-18-vw md:pb-24.5-vw flex md:rounded-lg-20-vw max-md:p-0">
          <div className="md:w-10/12 flex flex-col md:gap-10-vw gap-7.5 w-full">
            <Typography
              $type="Heading2Big"
              className="font-formula md:text-center text-start w-full"
              $weight="700"
            >
              Clients Review
            </Typography>
            <ReviewCarousel />
          </div>
        </section>
      </Container>
    </div>
  );
};
