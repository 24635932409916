import React from 'react';
import { Container } from '@/components/Containers/Container';
import { IconsPart } from './IconsPart/IconsPart';
import { ImagePart } from './ImagePart';
import { Typography } from '@/components/Typography/Typography';

export const ReadyToGoIntegration = () => {
  return (
    <div className="relative bg-light">
      <Container className="md:pt-18-vw md:pb-12.5-vw py-10">
        <section className="md:bg-white md:rounded-lg-20-vw max-md:p-0">
          <div className="panel flex max-md:flex-col rounded-none md:rounded-[20px] max-md:gap-6">
            <IconsPart />
            <ImagePart />
            <div className="md:max-w-[75%] md:hidden">
              <Typography
                $type="PMicroTen"
                className="opacity-40 font-jamjuree"
              >
                * Other help desks and third-party platforms can be integrated
                via API. <br /> **Integration with in-house systems and back-end
                infrastructure available as well.
              </Typography>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};
