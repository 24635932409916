export const REVIEWS_ITEMS = [
  {
    id: 0,
    title: 'Ann Kuss, CEO',
    name: 'Outstaff Your Team',
    description:
      "CoSupport AI was set up in just three days, seamlessly integrating with our CRM and reducing response times by 50%. The transparent pricing made budgeting easy, and the generative AI for customer support improved our workflow without needing extra training. We're thrilled with the efficiency boost.",
    img: 'img/main-page/reviews/kuss.png',
  },
  {
    id: 1,
    title: 'Karyna Naminas, CEO',
    name: 'Label Your Data',
    description:
      'CoSupport AI transformed our decision-making by giving non-technical team members easy access to data insights through Slack. This AI for customer support is simple to use, integrates quickly, and delivers faster analytics across departments. We’ve seen significant improvements in team performance and efficiency, all with transparent pricing that fits our budget.',
    img: 'img/main-page/reviews/naminas.png',
  },
  {
    id: 2,
    title: 'Eugene Gordienko, CEO',
    name: 'Quidget',
    description:
      'Our partnership with CoSupport AI has been a game-changer. CoSupport Customer reduced our response times by 32%, and their generative AI for customer support solutions have automated routine tasks for both our team and clients. The seamless integration, high accuracy, and ease of use have improved efficiency across the board. CoSupport AI has helped us expand our market offerings and boost customer satisfaction.',
    img: 'img/main-page/reviews/gordienko.png',
  },
  {
    id: 3,
    title: 'Anastasiia Lazareva, QA',
    name: 'Euphoric',
    description:
      "CoSupport Customer automates 80% of our responses, saving 10 hours per agent weekly. Integration was seamless, and the AI's accuracy improved with feedback. The generative AI for customer support learns quickly. We achieved a 38% decrease in first response time and a 31% reduction in total resolution time. The transparent pricing and high-quality performance have exceeded our expectations.",
    img: 'img/main-page/reviews/lazareva.png',
  },
  {
    id: 4,
    title: 'Josh Brown, CEO',
    name: 'Softorino',
    description:
      'CoSupport AI was easy to set up with our Zendesk system — this AI for customer support learned everything on its own with just an API key. It improved our team’s performance without needing any manual setup or training. We’d love to see built-in dashboards for better reporting, but overall, the  tool is super easy to use and a great value for the cost.',
    img: 'img/main-page/reviews/brown.png',
  },
  {
    id: 5,
    title: 'Daria Leshchenko, CEO',
    name: 'Support Your App',
    description:
      'CoSupport AI automates 150 daily responses, cutting first response time by 45%. It delivers accurate, relevant answers that improve with agent feedback. Integration was fast, and the AI for customer support has helped reduce costs while increasing customer satisfaction. The team’s expertise and responsiveness exceeded expectations, making the overall experience highly positive.',
    img: 'img/main-page/reviews/leshchenko.png',
  },
];
