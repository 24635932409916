import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { Typography, TypographyType } from '@/components/Typography/Typography';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useInView } from 'framer-motion';
import { clsx } from 'clsx';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Image from 'next/image';

type Props = {
  handleItemsChange?: (value: number | string) => void;
  delay?: number;
  contentClassName?: string;
  accordionClassName?: string;
  accordionItemClassName?: string;
  accordionTriggerClassName?: string;
  triggerType?: TypographyType;
  triggerWeight?: string;
  autoplay?: boolean;
  collapsible?: boolean;
  defaultValue?: undefined | string[];
  imageFit?: boolean;
  accordionItems: {
    id: string | number;
    triggerTitle: ReactElement | string;
    content: ReactElement | string;
    img?: string | ReactElement;
    isTriggerTitleNode?: boolean;
    isContentNode?: boolean;
  }[];
  aspectImage?: 'aspect-square' | 'aspect-video' | 'aspect-auto';
  hideOnMobileBreakPoint?: string;
};

export const MultipleAccordionComponent = ({
  handleItemsChange = () => {},
  accordionItems,
  delay = 6000,
  imageFit = false,
  contentClassName,
  accordionClassName,
  accordionItemClassName,
  accordionTriggerClassName,
  aspectImage,
  triggerType = 'Heading4',
  triggerWeight = '700',
  hideOnMobileBreakPoint = '(min-width: 768px)',
  autoplay = true,
  defaultValue = [],
}: Props) => {
  const [value, setValue] = useState(0);
  const interval = useRef<any>(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '-300px' });
  const hideOnMobile = useMediaQuery(hideOnMobileBreakPoint);

  useEffect(() => {
    if (autoplay) {
      if (isInView) {
        interval.current = setInterval(() => {
          setValue((prevIndex) =>
            prevIndex === accordionItems.length - 1 ? 0 : prevIndex + 1,
          );
        }, delay);
      } else {
        clearInterval(interval.current);
      }
    }

    return () => clearInterval(interval.current);
  }, [isInView, accordionItems.length, delay, autoplay]);

  useEffect(() => {
    handleItemsChange(value);
  }, [value, handleItemsChange]);

  const handleChange = (id: number | string) => {
    setValue(Number(id));
    handleItemsChange(Number(id));
    clearInterval(interval.current);
  };

  return (
    <Accordion
      ref={ref}
      type={'multiple'}
      className={clsx('w-full', accordionClassName)}
      defaultValue={defaultValue}
    >
      {accordionItems.map((item, index, arr) => (
        <AccordionItem
          onClick={() => handleChange(item.id)}
          key={item.id}
          value={String(item.id)}
          className={clsx(
            index + 1 === arr.length ? 'border-b-0' : '',
            'relative',
            accordionItemClassName,
          )}
        >
          <AccordionTrigger className={accordionTriggerClassName}>
            {item.isTriggerTitleNode ? (
              item.triggerTitle
            ) : (
              <Typography
                $type={triggerType}
                $weight={triggerWeight}
                className="font-formula text-left"
              >
                {item.triggerTitle}
              </Typography>
            )}
          </AccordionTrigger>
          <AccordionContent
            className={clsx('gap-6 flex flex-col', contentClassName)}
          >
            {item.isContentNode ? (
              item.content
            ) : (
              <Typography $type="PMedium" $weight="400">
                {item.content}
              </Typography>
            )}
            {typeof item.img === 'string'
              ? !hideOnMobile &&
                item.img && (
                  <div
                    className={clsx(
                      'relative rounded-md-10 overflow-hidden flex justify-center',
                      aspectImage,
                    )}
                  >
                    <Image
                      width="0"
                      height="0"
                      sizes="100vw"
                      className={clsx(
                        'w-full h-full',
                        imageFit ? 'object-contain' : 'object-cover',
                        imageFit && 'w-auto h-full',
                      )}
                      alt={`${item.triggerTitle} Image`}
                      src={item.img}
                    />
                  </div>
                )
              : !hideOnMobile && item.img}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
