import React from 'react';

const image = './img/main-page/ready-to-go/ready-to-go-desktop.png';
export const ImagePart = () => {
  return (
    <div className="md:w-1/2 w-full md:p-[30px]">
      <div className="overflow-hidden h-full w-full md:rounded-xl rounded-md-10 drop-shadow-sm max-md:border max-md:border-white relative min-h-[44.444vw]">
        <img
          className="h-full object-cover"
          src={image}
          width="100%"
          alt="ready-to-go integration image"
        />
      </div>
    </div>
  );
};
