import React from 'react';
import { Container } from '@/components/Containers/Container';
import { PATENT_DESCRIPTION_ITEMS } from './patent-description-items';
import { DescriptionCard } from './DescriptionCard';

export const PatentDescriptionBlock = () => {
  return (
    <Container className="relative">
      <section className="flex rounded-2xl md:py-9-vw md:px-15-vw p-8 flex-col bg-light md:gap-10-vw gap-4">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 md:gap-12.5-vw gap-6">
          {PATENT_DESCRIPTION_ITEMS.map((item) => (
            <DescriptionCard key={item.id} item={item} />
          ))}
        </div>
      </section>
    </Container>
  );
};
