import React from 'react';
import { Typography } from '@/components/Typography/Typography';

type CardProps = {
  item: {
    id: number;
    title: string;
    description: string;
    name: string;
    img: string;
  };
};
export const Card = ({ item }: CardProps) => {
  return (
    <div className="h-full">
      <div className="flex flex-col md:gap-5-vw gap-4">
        <div className="flex items-center md:gap-5-vw gap-4">
          <div className="overflow-hidden h-full w-full relative min-h-[171px] max-md:max-h-[171px] max-md:max-w-[171px] md:min-h-[11.875vw] md:max-w-[11.875vw] aspect-square">
            <img
              className="h-full object-cover"
              src={item.img}
              width="100%"
              alt={`${item.name} image`}
            />
          </div>
          <div className="flex flex-col md:gap-2.5-vw gap-2.5">
            <div className="flex-col flex">
              <Typography
                $type="PMedium"
                className="font-formula"
                $weight="700"
              >
                {item.title}
              </Typography>
              <Typography
                $type="PLittle"
                className="font-jamjuree"
                $weight="500"
              >
                {item.name}
              </Typography>
            </div>
            <svg
              className="md:min-w-[6.806vw] md:min-h-[1.25vw] min-w-24 min-h-4 md:max-w-[6.806vw] md:max-h-[1.25vw] max-w-24 max-h-4"
              width="98"
              height="20"
              viewBox="0 0 98 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49331 2.16208C8.81758 1.56983 9.66826 1.56983 9.99252 2.16208L11.9277 5.69667C12.0509 5.92161 12.2685 6.07935 12.5206 6.12637L16.4881 6.86644C17.1541 6.99067 17.4177 7.80347 16.9513 8.29487L14.1821 11.2124C14.0048 11.3992 13.9212 11.6559 13.9545 11.9112L14.4736 15.8962C14.5609 16.5666 13.872 17.0682 13.2608 16.7792L9.60822 15.0521C9.37696 14.9427 9.10887 14.9427 8.87761 15.0521L5.22499 16.7792C4.61379 17.0682 3.92491 16.5666 4.01224 15.8962L4.53136 11.9112C4.56462 11.6559 4.48101 11.3992 4.30377 11.2124L1.53457 8.29487C1.06816 7.80347 1.3317 6.99067 1.99772 6.86644L5.9652 6.12637C6.21729 6.07935 6.43494 5.92161 6.55809 5.69667L8.49331 2.16208Z"
                fill="#F9CD16"
              />
              <path
                d="M28.2355 2.16208C28.5598 1.56983 29.4104 1.56983 29.7347 2.16208L31.6699 5.69667C31.7931 5.92161 32.0107 6.07935 32.2628 6.12637L36.2303 6.86644C36.8963 6.99067 37.1599 7.80347 36.6934 8.29487L33.9242 11.2124C33.747 11.3992 33.6634 11.6559 33.6967 11.9112L34.2158 15.8962C34.3031 16.5666 33.6142 17.0682 33.003 16.7792L29.3504 15.0521C29.1191 14.9427 28.8511 14.9427 28.6198 15.0521L24.9672 16.7792C24.356 17.0682 23.6671 16.5666 23.7544 15.8962L24.2736 11.9112C24.3068 11.6559 24.2232 11.3992 24.046 11.2124L21.2768 8.29487C20.8104 7.80347 21.0739 6.99067 21.7399 6.86644L25.7074 6.12637C25.9595 6.07935 26.1771 5.92161 26.3003 5.69667L28.2355 2.16208Z"
                fill="#F9CD16"
              />
              <path
                d="M48.0558 2.16208C48.3801 1.56983 49.2308 1.56983 49.555 2.16208L51.4902 5.69667C51.6134 5.92161 51.831 6.07935 52.0831 6.12637L56.0506 6.86644C56.7166 6.99067 56.9802 7.80347 56.5138 8.29487L53.7446 11.2124C53.5673 11.3992 53.4837 11.6559 53.517 11.9112L54.0361 15.8962C54.1234 16.5666 53.4345 17.0682 52.8233 16.7792L49.1707 15.0521C48.9395 14.9427 48.6714 14.9427 48.4401 15.0521L44.7875 16.7792C44.1763 17.0682 43.4874 16.5666 43.5747 15.8962L44.0939 11.9112C44.1271 11.6559 44.0435 11.3992 43.8663 11.2124L41.0971 8.29487C40.6307 7.80347 40.8942 6.99067 41.5602 6.86644L45.5277 6.12637C45.7798 6.07935 45.9974 5.92161 46.1206 5.69667L48.0558 2.16208Z"
                fill="#F9CD16"
              />
              <path
                d="M67.8136 2.16208C68.1379 1.56983 68.9886 1.56983 69.3128 2.16208L71.2481 5.69667C71.3712 5.92161 71.5889 6.07935 71.8409 6.12637L75.8084 6.86644C76.4744 6.99067 76.738 7.80347 76.2716 8.29487L73.5024 11.2124C73.3251 11.3992 73.2415 11.6559 73.2748 11.9112L73.7939 15.8962C73.8812 16.5666 73.1924 17.0682 72.5812 16.7792L68.9285 15.0521C68.6973 14.9427 68.4292 14.9427 68.1979 15.0521L64.5453 16.7792C63.9341 17.0682 63.2452 16.5666 63.3326 15.8962L63.8517 11.9112C63.8849 11.6559 63.8013 11.3992 63.6241 11.2124L60.8549 8.29487C60.3885 7.80347 60.652 6.99067 61.318 6.86644L65.2855 6.12637C65.5376 6.07935 65.7552 5.92161 65.8784 5.69667L67.8136 2.16208Z"
                fill="#F9CD16"
              />
              <path
                d="M87.6418 2.16208C87.966 1.56983 88.8167 1.56983 89.141 2.16208L91.0762 5.69667C91.1993 5.92161 91.417 6.07935 91.6691 6.12637L95.6366 6.86644C96.3026 6.99067 96.5661 7.80347 96.0997 8.29487L93.3305 11.2124C93.1533 11.3992 93.0696 11.6559 93.1029 11.9112L93.622 15.8962C93.7094 16.5666 93.0205 17.0682 92.4093 16.7792L88.7567 15.0521C88.5254 14.9427 88.2573 14.9427 88.026 15.0521L84.3734 16.7792C83.7622 17.0682 83.0733 16.5666 83.1607 15.8962L83.6798 11.9112C83.7131 11.6559 83.6294 11.3992 83.4522 11.2124L80.683 8.29487C80.2166 7.80347 80.4801 6.99067 81.1462 6.86644L85.1136 6.12637C85.3657 6.07935 85.5834 5.92161 85.7065 5.69667L87.6418 2.16208Z"
                fill="#F9CD16"
              />
            </svg>
          </div>
        </div>
        <div className="">
          <Typography
            $type="PMedium"
            className="font-jamjuree opacity-70"
            $weight="400"
          >
            {item.description}
          </Typography>
        </div>
      </div>
    </div>
  );
};
