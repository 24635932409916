import React from 'react';
import { PatentBlock } from './PatentBlock';
import { PatentDescriptionBlock } from './PatentDescriptionBlock';

export const PatentSection = () => {
  return (
    <section className="relative bg-white md:pt-18-vw pt-10 ">
      <PatentBlock />
      <PatentDescriptionBlock />
    </section>
  );
};
