import React, { ReactNode } from 'react';
import { Typography } from '@/components/Typography/Typography';
import Link from 'next/link';
import { COLORS } from '@/constants/colors';

type Props = {
  icon: { icon: ReactNode; id: number; helpText: string; link: string };
};

export const IconComponent = ({ icon }: Props) => {
  return (
    <div className="flex flex-[1_0_33.33%] items-center justify-start md:gap-3.5-vw gap-2">
      {icon.icon}
      <div>
        {icon.link ? (
          <Link href={icon.link}>
            <Typography
              $color={COLORS.LINK}
              $type="PMedium"
              $weight="600"
              className="font-jamjuree text-start underline italic hover:text-blue-400 transition"
            >
              {icon.helpText}
            </Typography>
          </Link>
        ) : (
          <Typography $type="PMedium" className="font-jamjuree text-start">
            {icon.helpText}
          </Typography>
        )}
      </div>
    </div>
  );
};
