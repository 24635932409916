export const PATENT_DESCRIPTION_ITEMS = [
  {
    id: 0,
    title: 'Patented AI Architecture',
    description:
      'Officially recognized by the U.S. Patent Office, our specialized generative AI for customer support technology ensures highly accurate and efficient responses tailored to your business needs.',
  },
  {
    id: 1,
    title: 'Enhanced Data Security',
    description:
      'Client-specific proprietary data is securely stored and processed without external databases, reducing vulnerability and minimizing data leakage risks. Our AI for customer support learns quickly, further protecting your data.',
  },
  {
    id: 2,
    title: 'Faster Response Times',
    description:
      'CoSupport AI replies in milliseconds for individual queries and within 5 seconds for complex message threads — significantly faster than industry standards.',
  },
  {
    id: 3,
    title: 'Cost-Effective',
    description:
      'Our resource-efficient AI for customer support models reduce operational costs through transparent pricing, providing a fully functional AI solution.',
  },
];
