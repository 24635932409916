import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { Card } from './Card';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { BenefitCarousel } from './BenefitCarousel';
import { BENEFIT_ITEMS } from './carousel-items';

export const WhoBenefitsFromCoSupportAI = () => {
  const isMdUp = useMediaQuery('(min-width: 768px)');

  return (
    <div className="relative bg-light">
      <Container className="md:pt-12.5-vw md:pb-24.5-vw max-md:pb-10 relative">
        <section className="flex flex-col md:gap-5-vw gap-4">
          <div>
            <Typography
              $type="Heading2Big"
              className="font-formula"
              $weight="700"
            >
              Who Benefits from CoSupport AI?
            </Typography>
          </div>
          {isMdUp ? (
            <BenefitCarousel />
          ) : (
            <div className="flex flex-col gap-4">
              {BENEFIT_ITEMS.map((item) => (
                <Card key={item.id} item={item} />
              ))}
            </div>
          )}
        </section>
      </Container>
    </div>
  );
};
