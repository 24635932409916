import React, { ReactElement } from 'react';
import Link from 'next/link';
import { PATH_AUTHORS, PATH_TAGS } from '@/constants/spa-routes';
import { EyeIcon } from '@/assets/icons/EyeIcon';
import { clsx } from 'clsx';

type ArticleProps = {
  id: number;
  attributes: {
    Hero_Image: {
      data: {
        attributes: {
          url: string;
          alternativeText: string;
        };
      };
    };
    author: {
      data: {
        id: number;
        attributes: {
          Name: string;
          Position: string;
          Avatar: {
            data: {
              attributes: {
                url: string;
              };
            };
          };
        };
      };
    };
    URL: string;
    Title: string;
    Short_Description: string;
    publishedAt: string;
    views: number;
    tags: {
      data: [
        {
          id: number;
          attributes: {
            Name: string;
          };
        },
      ];
    };
  };
};
function PublishDate({
  publishDate,
}: {
  publishDate: ArticleProps['attributes']['publishedAt'];
}) {
  const formattedDate = publishDate
    ? new Date(publishDate).toLocaleString('en', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      })
    : null;

  if (!formattedDate) {
    return null;
  }
  return <span className="published-date">{formattedDate}</span>;
}

function AuthorInfo({
  author,
}: {
  author: ArticleProps['attributes']['author'];
}) {
  if (!author?.data) {
    return null;
  }

  const authorUrl = author.data.attributes.Name.toLowerCase()
    .split(' ')
    .join('-');

  return (
    <div className="article-author">
      <div className="article-author-avatar">
        <Link href={`${PATH_AUTHORS.ROOT}/${authorUrl}`}>
          <img
            src={author?.data?.attributes?.Avatar?.data?.attributes?.url}
            width="60px"
            style={{ borderRadius: '50%' }}
          />
        </Link>
      </div>
      <div className="article-author-name">
        <p>
          by{' '}
          <Link
            href={`${PATH_AUTHORS.ROOT}/${authorUrl}`}
            className="hover:underline"
          >
            {author.data.attributes.Name}
          </Link>
        </p>
        <p>
          <Link
            href={`${PATH_AUTHORS.ROOT}/${authorUrl}`}
            className="!no-underline"
          >
            <span className="grey">{author.data.attributes.Position}</span>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default function Article({
  article,
  className,
  authorHidden = false,
}: {
  article: ArticleProps;
  className?: string;
  authorHidden?: boolean;
}): ReactElement {
  return (
    <div className={clsx(className, 'article next')}>
      <div className="image-block cursor-pointer">
        <Link href={`${article.attributes.URL}`}>
          <img
            src={article.attributes?.Hero_Image?.data?.attributes?.url}
            alt={
              article.attributes?.Hero_Image?.data?.attributes
                ?.alternativeText || ''
            }
            width="100%"
          />
        </Link>
      </div>
      <div className="wrapper-block">
        <div className="title-block">
          <div className="flex gap-3.5 items-center">
            <PublishDate publishDate={article.attributes.publishedAt} />
            <div
              className="flex items-center gap-2 truncate views-counter-value"
              title={`${article?.attributes?.views || '0'} views`}
            >
              <EyeIcon className="min-w-5.5" />
              <span className="opacity-30 font-jamjuree truncate text-ebony font-semibold text-sm">
                {article?.attributes?.views || '0'}
              </span>
            </div>
          </div>
          {!authorHidden && <AuthorInfo author={article.attributes.author} />}
          <Link href={`${article.attributes.URL}`}>
            <h6>{article.attributes.Title}</h6>
          </Link>
        </div>
        <div className="content-block">
          <div>
            <Link href={`${article.attributes.URL}`}>
              <p>{article.attributes.Short_Description}</p>
            </Link>
          </div>
          <div className="content-tags">
            {article.attributes.tags.data.map((tag) => {
              const url = tag.attributes.Name.toLowerCase()
                .trim()
                .replace(/\ /g, '-');
              return (
                <Link
                  prefetch={false}
                  key={`${article.id}-tag-${tag.id}`}
                  href={`${PATH_TAGS.ROOT}/${url}`}
                  className={`hover:[&>div]:bg-[#d8d8d8] [&>div]:transition-all duration-200`}
                >
                  <div className="tag">{tag.attributes.Name}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
