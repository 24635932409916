import React from 'react';
import styled from 'styled-components';
import { Content } from './Content/Content';

const SectionContainer = styled.section({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

const ContentContainer = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F1F6F6',
});

export const FirstSection = () => {
  return (
    <SectionContainer className="relative bg-ebony">
      <ContentContainer className="mt-15 max-sm_mini:mt-[70px] backdrop-blur-sm">
        <Content />
      </ContentContainer>
    </SectionContainer>
  );
};
