import React from 'react';
import { Typography } from '@/components/Typography/Typography';

type CardProps = {
  item: {
    id: number;
    title: string;
    description: string;
    icon: React.ReactNode;
  };
};
export const Card = ({ item }: CardProps) => {
  return (
    <div className="border h-full border-[#C7CFD380] md:rounded-2xl rounded-md-10 md:p-7.5-vw px-4 py-6">
      <div className="flex md:gap-5-vw gap-4">
        <div className="md:py-6-vw">{item.icon}</div>
        <div className="flex flex-col gap-1 md:py-6-vw">
          <Typography
            $type="PMedium"
            className="font-jamjuree italic"
            $weight="600"
          >
            {item.title}
          </Typography>
          <Typography
            $type="PMedium"
            className="font-jamjuree opacity-70"
            $weight="400"
          >
            {item.description}
          </Typography>
        </div>
      </div>
    </div>
  );
};
