import React from 'react';
import { motion } from 'framer-motion';

type EllipseProps = {
  delay?: number;
  x?: number[];
  y?: number[];
  scale?: number[];
  className?: string;
};

const PurpleEllipse = ({
  y = [-150, 0, -150],
  x = [-250, 50, -250],
  scale = [1, 0.8, 1],
  delay = 0,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 8,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-purple lg:w-[21.528vw] w-[215px] h-[215px] lg:h-[21.528vw] rounded-full lg:blur-[400px] max-md:opacity-70" />
    </motion.div>
  );
};

const BlueEllipse = ({
  y = [200, 0, 200],
  x = [-150, 0, -150],
  scale = [1, 0.8, 1],
  delay = 1,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-malibu lg:w-[21.528vw] w-[240px] h-[240px] lg:h-[21.528vw] rounded-full lg:blur-[400px] max-md:opacity-70" />
    </motion.div>
  );
};

const YellowEllipse = ({
  y = [-250, 0, -250],
  x = [-150, 50, -150],
  scale = [1, 0.8, 1],
  delay = 2,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 12,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-chartreuse_yellow lg:w-[21.528vw] w-[215px] h-[215px] lg:h-[21.528vw] rounded-full lg:blur-[400px] max-md:opacity-70" />
    </motion.div>
  );
};

export const BackgroundAnimation = () => {
  return (
    <div className="absolute h-full w-full  top-0">
      <motion.div
        className="absolute w-full h-full"
        animate={{
          opacity: 1,
          display: 'block',
        }}
        transition={{ duration: 1 }}
      >
        <BlueEllipse className="absolute left-1/4 top-1/4" />
        <PurpleEllipse className="absolute top-1/4 left-3/4" />
        <YellowEllipse className="absolute right-1/4 bottom-0" />
      </motion.div>
    </div>
  );
};
