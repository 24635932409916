import React, { useEffect, useRef, useState } from 'react';
import { Card } from './Card';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { useInView } from 'framer-motion';
import Autoplay from 'embla-carousel-autoplay';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { REVIEWS_ITEMS } from './carousel-items';
import { clsx } from 'clsx';

export const ReviewCarousel = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '-300px' });
  const isMdUp = useMediaQuery('(min-width: 768px)');

  const plugin = useRef(Autoplay({ delay: 10000, stopOnInteraction: true }));

  const handleGoPrev = () => {
    if (plugin.current.isPlaying()) {
      plugin.current.stop();
    }
    api?.scrollPrev();
  };
  const handleGoNext = () => {
    if (plugin.current.isPlaying()) {
      plugin.current.stop();
    }
    api?.scrollNext();
  };

  useEffect(() => {
    if (isMdUp) {
      if (isInView) {
        plugin.current.play();
      } else {
        plugin.current.stop();
      }
    }
  }, [isInView, isMdUp]);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);
  return (
    <div>
      <Carousel
        ref={ref}
        plugins={[plugin.current]}
        setApi={setApi}
        opts={{
          align: 'center',
          loop: true,
        }}
        className="w-full"
      >
        <div className="max-md:pb-3 max-md:w-[calc(100%+2rem)] max-md:-ml-4">
          <CarouselContent className="max-md:m-4">
            {REVIEWS_ITEMS.map((item, index) => (
              <CarouselItem
                key={item.id}
                className={clsx(
                  index !== REVIEWS_ITEMS.length - 1 && 'max-md:mr-1',
                  'md:basis-[70%] lg:basis-[45%] shrink-0 md:pl-17.5-vw pl-0 transition-opacity duration-500',
                  index === current ? 'opacity-100' : 'opacity-30',
                )}
              >
                <div className="p-1 h-full">
                  <Card item={item} />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </div>

        {isMdUp && (
          <>
            <CarouselPrevious
              onClick={handleGoPrev}
              className="bg-transparent [&_svg>path]:stroke-ebony [&_svg>path:first-child]:fill-ebony border-ebony top-full left-[45.3%] mt-12-vw"
            />
            <CarouselNext
              onClick={handleGoNext}
              className="bg-transparent [&_svg>path]:stroke-ebony [&_svg>path:first-child]:fill-ebony border-ebony top-full right-[45.3%] mt-12-vw"
            />
          </>
        )}
      </Carousel>
      {!isMdUp && (
        <div className="flex gap-4 justify-center px-4 py-2.5 text-center text-sm text-muted-foreground absolute bottom-5 left-0">
          {REVIEWS_ITEMS.map((slide, index) => (
            <div
              onClick={() => api?.scrollTo(index)}
              key={`${slide.id}`}
              className={clsx(
                'h-[9px] w-[9px] rounded-full cursor-pointer bg-ebony',
                slide.id !== current && 'opacity-40',
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
