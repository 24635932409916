import React, { useEffect, useRef, useState } from 'react';
import { Card } from './Card';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { motion, useInView } from 'framer-motion';
import Autoplay from 'embla-carousel-autoplay';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { BENEFIT_ITEMS } from './carousel-items';

export const BenefitCarousel = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '-300px' });
  const isMdUp = useMediaQuery('(min-width: 768px)');

  const plugin = useRef(Autoplay({ delay: 6000 }));

  const handleGoPrev = () => {
    if (plugin.current.isPlaying()) {
      plugin.current.stop();
    }
    api?.scrollPrev();
  };
  const handleGoNext = () => {
    if (plugin.current.isPlaying()) {
      plugin.current.stop();
    }
    api?.scrollNext();
  };

  useEffect(() => {
    if (isMdUp) {
      if (isInView) {
        plugin.current.play();
      } else {
        plugin.current.stop();
      }
    }
  }, [isInView, isMdUp]);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return (
    <Carousel
      ref={ref}
      plugins={[plugin.current]}
      setApi={setApi}
      opts={{
        align: 'start',
      }}
      className="w-full"
    >
      <CarouselContent>
        {BENEFIT_ITEMS.map((item) => (
          <CarouselItem key={item.id} className="md:basis-1/2 lg:basis-1/3">
            <div className="p-1 h-full">
              <Card item={item} />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <motion.div
        animate={{
          opacity: api?.canScrollNext() ? 1 : 0,
          transition: {
            duration: 0.4,
          },
        }}
        className="w-2/12 h-full absolute right-0 top-0 bg-[linear-gradient(270deg,#F1F6F6_27.63%,rgba(241,246,246,0)100%)]"
      />
      <CarouselPrevious
        onClick={handleGoPrev}
        className="bg-transparent [&_svg>path]:stroke-ebony [&_svg>path:first-child]:fill-ebony border-ebony left-full -ml-24.5-vw !-top-10-vw"
      />
      <CarouselNext
        onClick={handleGoNext}
        className="bg-transparent [&_svg>path]:stroke-ebony [&_svg>path:first-child]:fill-ebony border-ebony !-top-10-vw"
      />
    </Carousel>
  );
};
