import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';

type Props = {
  selectedFilter: number;
};

const images = [
  '/img/main-page/see-cosupport/see-customer-desktop.png',
  '/img/main-page/see-cosupport/see-agent-desktop.png',
  '/img/main-page/see-cosupport/see-bi-desktop.png',
];
export const ImagePart = ({ selectedFilter }: Props) => {
  return (
    <div className="w-full flex-grow">
      <div className="h-full w-full md:rounded-xl relative max-md:min-h-[420px] min-h-[45.9vw]">
        {images.map((image, index) => (
          <div
            key={image}
            className="wrapper h-full w-auto absolute md:rounded-md-10-vw rounded-md-10"
          >
            <motion.div
              className="h-full"
              animate={{
                opacity: selectedFilter === index ? 1 : 0,
              }}
              transition={{ duration: 0.6 }}
            >
              <Image
                width="0"
                height="0"
                sizes="100vw"
                className="w-full h-full object-contain"
                alt={image}
                src={image}
              />
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};
