import React from 'react';
import { motion } from 'framer-motion';

type EllipseProps = {
  delay?: number;
  x?: number[];
  y?: number[];
  scale?: number[];
  className?: string;
};

const PurpleEllipse = ({
  y = [-150, 0, -150],
  x = [-150, 50, -150],
  scale = [1, 0.8, 1],
  delay = 0,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-purple md:w-[21.528vw] w-[235px] h-[235px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-40" />
    </motion.div>
  );
};

const BlueEllipse = ({
  y = [100, 0, 100],
  x = [-150, 0, -150],
  scale = [1, 0.8, 1],
  delay = 1,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 12,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-malibu md:w-[21.528vw] w-[260px] h-[260px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-40" />
    </motion.div>
  );
};

const YellowEllipse = ({
  y = [-150, 0, -150],
  x = [-150, 50, -150],
  scale = [1, 0.8, 1],
  delay = 2,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 14,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-chartreuse_yellow md:w-[21.528vw] w-[215px] h-[215px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-40" />
    </motion.div>
  );
};

export const BackgroundAnimation = () => {
  return (
    <div className="absolute h-full w-full  top-0">
      <BlueEllipse className="absolute left-1/4 top-1/4" />
      <PurpleEllipse className="absolute top-1/4 left-3/4" />
      <YellowEllipse className="absolute right-1/4 bottom-0" />
    </div>
  );
};
