import React from 'react';
import { motion } from 'framer-motion';

type EllipseProps = {
  delay?: number;
  x?: number[];
  y?: number[];
  scale?: number[];
  className?: string;
};

const PurpleEllipse = ({
  y = [-150, 0, -150],
  x = [250, 50, 250],
  scale = [1, 0.8, 1],
  delay = 0,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 10,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-purple md:w-[21.528vw] w-[435px] h-[435px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-50" />
    </motion.div>
  );
};

const BlueEllipse = ({
  y = [100, 0, 100],
  x = [150, 50, 150],
  scale = [1, 0.8, 1],
  delay = 1,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 13,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-malibu md:w-[21.528vw] w-[460px] h-[460px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-50" />
    </motion.div>
  );
};

const YellowEllipse = ({
  y = [-250, 0, -250],
  x = [150, 50, 150],
  scale = [1, 0.8, 1],
  delay = 2,
  className,
}: EllipseProps) => {
  return (
    <motion.div
      className={className}
      animate={{
        scale: scale,
        x: x,
        y: y,
      }}
      transition={{
        duration: 15,
        delay: delay,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'easeInOut',
      }}
    >
      <div className="bg-chartreuse_yellow md:w-[21.528vw] w-[435px] h-[435px] md:h-[21.528vw] rounded-full md:blur-[450px] max-md:opacity-50" />
    </motion.div>
  );
};

export const BackgroundAnimation = () => {
  return (
    <div className="absolute h-full w-full">
      <motion.div
        className="absolute w-full h-full"
        animate={{
          opacity: 1,
          display: 'block',
        }}
        transition={{ duration: 1 }}
      >
        <PurpleEllipse className="absolute md:top-1/4 top-[17%] -left-[10%] md:left-1/2" />
        <YellowEllipse className="z-50 absolute md:right-1/4 right-1/2 bottom-0" />
        <BlueEllipse className="absolute left-1/4 md:top-2/4 top-[40%]" />
      </motion.div>
    </div>
  );
};
