import React from 'react';
import Head from 'next/head';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Loader } from '@/components/Loader/Loader';
import { useLoader } from '@/hooks/useLoader';
import { MainPageIcon } from '@/assets/icons/mainPageIcon';
import { HowCoSupportAIWorks } from '@/components/PagesSections/MainPageSections/HowCoSupportAIWorks/HowCoSupportAIWorks';
import { SecondSection } from '@/components/PagesSections/MainPageSections/SecondSection/SecondSection';
import { RedefineCustomerSupportSection } from '@/components/PagesSections/MainPageSections/RedefineCustomerSupportSection/RedefineCustomerSupportSection';
import { SeeCoSupportAIInAction } from '@/components/PagesSections/MainPageSections/SeeCoSupportAIInAction/SeeCoSupportAIInAction';
import { SuperChargeYourBusiness } from '@/components/PagesSections/MainPageSections/SuperChargeYourBusiness/SuperChargeYourBusiness';
import { ReadyToGoIntegration } from '@/components/PagesSections/MainPageSections/ReadyToGoIntegration/ReadyToGoIntegration';
import { PatentSection } from '@/components/PagesSections/MainPageSections/PatentSection/PatentSection';
import Script from 'next/script';
import { FirstSection } from '@/components/PagesSections/MainPageSections/FirstSection/FirstSection';
import { WhoBenefitsFromCoSupportAI } from '@/components/PagesSections/MainPageSections/WhoBenefitsFromCoSupportAI/WhoBenefitsFromCoSupportAI';
import { CoSupportAIDelivers } from '@/components/PagesSections/MainPageSections/CoSupportAIDelivers/CoSupportAIDelivers';
import { SuccessStoriesSection } from '@/components/PagesSections/MainPageSections/SuccessStoriesSection/SuccessStoriesSection';
import { ClientsReview } from '@/components/PagesSections/MainPageSections/ClientsReview/ClientsReview';
import { EnterpriseLevelData } from '@/components/PagesSections/MainPageSections/EnterpriseLevelData/EnterpriseLevelData';
import { FAQMainPage } from '@/components/PagesSections/MainPageSections/FAQMainPage/FAQMainPage';
import { fetchFromServer } from '../fetch';
import { Articles } from '@/pages/articles';
import { LatestArticlesSection } from '@/components/PagesSections/MainPageSections/LatestArticlesSection/LatestArticlesSection';
import { LastSection } from '@/components/PagesSections/MainPageSections/LastSection/LastSection';

interface MainPageProps {
  originUrl: string;
  articles: Articles[];
}

export default function MainPage({ originUrl, articles }: MainPageProps) {
  const loading = useLoader();

  return (
    <>
      <Head>
        <meta key="utf" charSet="UTF-8" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          key="facebook"
          name="facebook-domain-verification"
          content="5q9rm986matfhco68cm9zmwzh78kw4"
        />
        <title>
          Generative AI for Customer Support: Smarter, Faster, and More
          Efficient
        </title>
        <meta
          key="subject"
          name="subject"
          content="CoSupport AI | an AI Business Analyst for Your Business"
        />
        <meta
          key="description"
          name="description"
          content="Transform your business with generative AI for customer support. CoSupport AI automates responses, boosts agent productivity, and provides valuable business insights"
        />
        <link key="canonical" rel="canonical" href={`${originUrl}/`} />
        <meta
          key="ogtitle"
          property="og:title"
          content="CoSupport AI | an AI Business Analyst for Your Business"
        />
        <meta key="ogtype" property="og:type" content="website" />
        <meta key="oglocale" property="og:locale" content="en_US" />
        <meta property="og:url" content={`${originUrl}/`} />
        <meta
          key="ogurl"
          property="og:image"
          content={`${originUrl}/img/open-graph/main-page.png`}
        />
        <meta
          key="ogsitename"
          property="og:site_name"
          content={`${originUrl}/`}
        />
        <meta
          key="ogdescription"
          property="og:description"
          content="CoSupport AI – the solution that enhances customer support and empowers businesses with data-driven insights. It ensures exceptional customer service and delivers unparalleled Business Intelligence to core departments. CoSupport AI consists of 3 components: Agent, Customer, and BI."
        />
        <script
          key="organization-jsonld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org",
            "@type": "Organization",
            "name": "CoSupport AI",
            "url": "https://cosupport.ai/",
            "logo": "https://cosupport.ai/img/logo.png",
            "foundingDate": "October 11, 2020",
            "award": "https://cosupport.ai/articles/cosupport-wins-ai-world-series-award-2024",
            "founders": [
          {
            "@type": "Person",
            "name": "Daria Leshchenko",
            "description": "CoAdvisor, CoFounder",
            "sameAs": "https://www.linkedin.com/in/darialeshchenko"
          },
          {
            "@type": "Person",
            "name": "Roman Lutsyshyn",
            "description": "CoFounder, CTO",
            "sameAs": "https://www.linkedin.com/in/roman-lutsushyn"
          } ],
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "5356 Hermitage Ave",
            "addressLocality": "Valley Village",
            "addressRegion": "CA",
            "postalCode": "91607",
            "addressCountry": "US"
          },
            "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "email": "contact@cosupports.ai"
          },
            "sameAs": [
            "https://www.linkedin.com/company/98213887",
            "https://www.instagram.com/cosupport.ai/",
            "https://twitter.com/cosupportai",
            "https://www.youtube.com/channel/UC9RNK2lsGajtGYx-cNfFxKw",
            "https://www.facebook.com/CoSupportAI"
            ]}`,
          }}
        />
        <Script
          id="googletagmanager3"
          key="googletagmanager3"
          strategy="beforeInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=AW-11237889467"
        />
        <Script
          id="googletagmanager4"
          key="googletagmanager4"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11237889467');
          `,
          }}
        />
        <Script
          id="googletagmanager5"
          key="googletagmanager5"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WXHFTKL9');
          `,
          }}
        />
      </Head>
      <MainPageIcon />
      {loading && <Loader />}
      <Header pathname={`/`} />
      <FirstSection />
      <SecondSection />
      <HowCoSupportAIWorks />
      <RedefineCustomerSupportSection />
      <SeeCoSupportAIInAction />
      <SuperChargeYourBusiness />
      <ReadyToGoIntegration />
      <WhoBenefitsFromCoSupportAI />
      <CoSupportAIDelivers />
      <SuccessStoriesSection />
      <ClientsReview />
      <EnterpriseLevelData />
      <PatentSection />
      <LatestArticlesSection articles={articles} />
      <FAQMainPage />
      <LastSection />
      <Footer />
    </>
  );
}

export async function getServerSideProps() {
  const originUrl: string = process.env.ORIGIN_URL || 'https://cosupport.ai';

  try {
    const articles = await fetchFromServer(
      'articles?sort=publishedAt:desc&pagination[page]=1&pagination[pageSize]=3&fields[0]=URL&fields[1]=Title&fields[2]=Short_Description&fields[3]=publishedAt&fields[4]=views&populate=author.Avatar,Hero_Image,IMAGES_DESKTOP,IMAGES_MOBILE,tags.Name',
    );

    return {
      props: {
        articles: articles.data,
        meta: articles.meta,
        originUrl,
      },
    };
  } catch (e) {
    return {
      props: {
        articles: [],
        error: { name: (e as Error).name, message: (e as Error).message },
        originUrl,
      },
    };
  }
}
