import React, { useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { AccordionPart } from './AccordionPart';
import { ImagePart } from './ImagePart';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export const RedefineCustomerSupportSection = () => {
  const isMdUp = useMediaQuery('(min-width: 768px)');

  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleItemsChange = (value: string | number) => {
    setSelectedFilter(Number(value));
  };

  return (
    <section className="relative">
      <Container className="md:py-18-vw py-10">
        <div className="max-md:p-0 bg-light md:rounded-l-lg-20-vw rounded-md-10 z-10">
          <div className="lg:min-h-[50vw] md:gap-5 flex max-md:flex-col rounded-none md:rounded-[20px] max-md:gap-2.5 flex-grow justify-end max-md:px-4 w-full max-lg:py-10">
            <AccordionPart handleItemsChange={handleItemsChange} />
            {isMdUp && <ImagePart selectedFilter={selectedFilter} />}
          </div>
        </div>
      </Container>
    </section>
  );
};
