import React from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { Card } from './Card';
import { ENTERPRISE_ITEMS } from './enterprise-items';

export const EnterpriseLevelData = () => {
  return (
    <div className="relative bg-light">
      <Container className="md:pt-12.5-vw pt-10 md:pb-24.5-vw pb-10 relative">
        <section className="flex flex-col md:gap-10-vw gap-4">
          <div className="flex flex-col md:gap-2.5-vw gap-2 md:w-8/12">
            <Typography
              $type="Heading2Big"
              className="font-formula"
              $weight="700"
            >
              Enterprise-Level Data Protection You Can Trust
            </Typography>
            <Typography
              $type="Heading4"
              className="font-jamjuree"
              $weight="400"
            >
              CoSupport AI for customer support ensures top-tier data privacy
              and protection, tailored to the needs of large-scale operations
              with dedicated servers in the US and EU.
            </Typography>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 md:gap-4-vw gap-4">
            {ENTERPRISE_ITEMS.map((item) => (
              <Card key={item.id} item={item} />
            ))}
          </div>
        </section>
      </Container>
    </div>
  );
};
