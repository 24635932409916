export const EyeIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z"
        fill="#05050D"
        fillOpacity="0.3"
      />
      <path
        d="M21.271 10.7662C20.4623 8.67463 19.0586 6.8658 17.2331 5.56331C15.4077 4.26082 13.2406 3.52177 10.9997 3.4375C8.75881 3.52177 6.59172 4.26082 4.76628 5.56331C2.94084 6.8658 1.53707 8.67463 0.728458 10.7662C0.673847 10.9173 0.673847 11.0827 0.728458 11.2338C1.53707 13.3254 2.94084 15.1342 4.76628 16.4367C6.59172 17.7392 8.75881 18.4782 10.9997 18.5625C13.2406 18.4782 15.4077 17.7392 17.2331 16.4367C19.0586 15.1342 20.4623 13.3254 21.271 11.2338C21.3256 11.0827 21.3256 10.9173 21.271 10.7662ZM10.9997 15.4688C10.1159 15.4688 9.25189 15.2067 8.517 14.7156C7.78212 14.2246 7.20935 13.5267 6.87112 12.7101C6.53289 11.8936 6.4444 10.995 6.61682 10.1282C6.78925 9.26134 7.21486 8.46508 7.83982 7.84012C8.46479 7.21515 9.26105 6.78954 10.1279 6.61712C10.9948 6.44469 11.8933 6.53318 12.7098 6.87141C13.5264 7.20964 14.2243 7.78241 14.7153 8.5173C15.2064 9.25218 15.4685 10.1162 15.4685 11C15.4666 12.1846 14.9952 13.3202 14.1576 14.1579C13.3199 14.9955 12.1843 15.4669 10.9997 15.4688Z"
        fill="#05050D"
        fillOpacity="0.3"
      />
    </svg>
  );
};
