import React, { useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { BackgroundAnimation } from './BackgroundAnimation';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { AccordionPart } from './AccordionPart';
import { ImagePart } from './ImagePart';

export const SeeCoSupportAIInAction = () => {
  const isMdUp = useMediaQuery('(min-width: 768px)');

  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleItemsChange = (value: string | number) => {
    setSelectedFilter(Number(value));
  };
  return (
    <div className="bg-ebony relative overflow-hidden">
      <BackgroundAnimation />
      <Container className="md:pt-16-vw max-md:backdrop-blur-[150px] pt-10 md:pb-16-vw pb-10 flex flex-col gap-10-vw">
        <div className="flex flex-col items-center md:gap-5-vw gap-5">
          <Typography
            as="h2"
            $weight="700"
            $type="Heading1"
            className="font-formula text-center"
            $color={COLORS.WHITE}
          >
            See CoSupport AI in Action
          </Typography>
          <Typography
            $color={COLORS.WHITE}
            $weight="400"
            $type="Heading4"
            className="font-jamjuree"
          >
            Experience CoSupport AI for customer support solutions firsthand
            – try interactive demos now
          </Typography>
        </div>
        <div className="flex gap-12-vw">
          <div className="md:basis-1/2">
            <AccordionPart
              isMdUp={isMdUp}
              handleItemsChange={handleItemsChange}
            />
          </div>
          {isMdUp && (
            <div className="basis-5/12 bg-white flex items-center justify-center bg-opacity-5 rounded-[40px] border border-[#FFFFFF33] md:p-5-vw">
              <ImagePart selectedFilter={selectedFilter} />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
