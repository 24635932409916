export const BENEFIT_ITEMS = [
  {
    id: 0,
    title: 'Executives and Founders',
    description:
      'Boost customer satisfaction, reduce operational costs, and gain actionable insights for strategic decision-making.',
  },
  {
    id: 1,
    title: 'Customer Support Managers',
    description:
      'Automate routine inquiries, reduce ticket backlog, and improve response accuracy while focusing on high-priority tasks.',
  },
  {
    id: 2,
    title: 'Product Managers',
    description:
      'Integrate AI-driven support seamlessly with your product, ensuring scalability and a better user experience.',
  },
  {
    id: 3,
    title: 'Sales Managers',
    description:
      'Leverage AI to automate pre-sales inquiries, identify upsell opportunities, and shorten sales cycles.',
  },
];
